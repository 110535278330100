<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('OneCPinCodeCreate')"
    @edit="(id) => onEdit('OneCPinCodeEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'OneCPinCodes',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'OneCPinCodes',
      tableCaption: 'Пин-коды 1с',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Пин-коды 1с',
        },
      ],
      tableHeaders: [
        {
          text: 'Рег.номер',
          alias: 'regNumber',
          order: 'regNumber',
        },
        { text: 'Тип', alias: 'type', order: 'type' },
        { text: 'Сервер', alias: 'serverName', order: 'serverName' },
        { text: 'Текущий пин-код', alias: 'currentPinCode', order: 'currentPinCode' },
        { text: 'Установлено', alias: 'isInstalled', order: 'isInstalled' },
        { text: 'Не использованные пин-коды', alias: 'unusedPinCodes', order: 'unusedPinCodes' },
        { alias: 'actions' },
      ],
    };
  },
};
</script>
